<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-table">
            <vuescroll>
              <a-form-model style="margin-top: 20px" ref="formModel" :rules="formModelRules" :model="formModel" layout="vertical"
                            :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-form-model-item label="内容" prop="about_us_content">
                  <a-textarea v-model="formModel.about_us_content" :rows="4" placeholder="请输入内容"></a-textarea>
                </a-form-model-item>
                <a-form-model-item label="详情" prop="about_us_details">
                  <custom-editor v-bind:content.sync="formModel.about_us_details"/>
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 10, offset: 8 }">
                  <a-button type="primary" :loading="$store.state.requestLoading"  @click="actionData">
                    确定
                  </a-button>
                  <a-button style="margin-left: 10px">
                    重置
                  </a-button>
                </a-form-model-item>
              </a-form-model>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {
        formModel: {
          about_us_content: '',
          about_us_details: ''
        },
        formModelRules: {
          about_us_content: [{required: true, message: '请输入内容', trigger: 'change'}],
          about_us_details: [{required: true, message: '请输入详情', trigger: 'change'}]
        }
      }
    },
    mounted() {
      this.getInfo();

    },
    methods: {
      /**
       * 添加，更新
       * @author wheat
       */
      actionData() {
        this.$refs.formModel.validate(async valid => {
          if (valid) {
            let res = await Api.Update(this.formModel);

            if (res && res['code'] == '0') {
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          }
        });
      },
      /**
       * 获取信息
       * @author wheat
       * */
      async getInfo() {
        let res = await Api.Info({})
        this.formModel = res
      }
    },
  }
</script>

<style scoped>

</style>
