import request from '@/utils/request';

// 信息
export function Info(params) {
    return request({
        url: '/admin/adv/aboutUs/detail',
        method: 'GET',
        params
    });
}

// 保存
export function Update(data) {
    return request({
        url: '/admin/adv/aboutUs/update',
        method: 'POST',
        data
    });
}

